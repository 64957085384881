





















import { Vue, Component, Prop } from 'vue-property-decorator'
import { MeetingMember } from '@tada-team/tdproto-ts'

@Component({
  components: {
    BaseAvatar: () => import('@/components/UI/BaseAvatar.vue'),
  },
})
export default class MeetingCardMembers extends Vue {
  @Prop({
    required: true,
    type: Array,
  }) members!: MeetingMember[]

  private showMembersCount = 5

  get hasMore () {
    return this.members.length > this.showMembersCount
  }

  get showMembers () {
    return this.members.slice(0, this.showMembersCount)
  }

  get moreCount () {
    return this.members.length - this.showMembersCount
  }
}
